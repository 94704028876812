import { FC, ReactElement } from 'react'

import styles from './NotchedBox.module.scss'

type NotchedBoxProps = {
    title?: ReactElement
    bodyClass?: string
    rootClass?: string
    titleClass?: string
    notchType?: 'top' | 'bottom' | 'all'
    texturize?: boolean
}

const NotchedBox: FC<NotchedBoxProps> = ({ title, bodyClass, rootClass, titleClass, notchType, children, texturize }) => {
    let notchClass = ''
    switch(notchType) {
        case 'top':
            notchClass = styles.topNotch
            break
        case 'bottom':
            notchClass = styles.bottomNotch
            break
        default:
            notchClass = styles.allNotch
    }

    const Title = () => {
        if(title) {
            return (
                <header className={`${styles.title} ${titleClass}`} data-testid={'notched-page-title'}>
                    <span>
                        {title}
                    </span>
                </header>
            )
        }
        return <></>
    }

    return (
        <section className={rootClass}>
            <Title />
            <article className={`${notchClass} ${!!texturize ? styles.texture : ''} ${bodyClass}`}>
                {children}
            </article>
        </section>
    )
}

export default NotchedBox
import { useLocation } from 'react-router-dom'

export const useQuery = () => {
    const location = useLocation()
    return new URLSearchParams(location.search)
}

export const useLocale = () => {
    const location = useLocation()
    return location.pathname.split('/')[1]
}
/*
    To split into a reusable library, configurations need to be split out into arguments
    and helper functions should local to the file
*/

import { v4 as uuidv4 } from 'uuid'
import { getAuthCookies } from 'utils/auth'

let analytics: Analytics | null = null

class Analytics {
    siteProduct: string
    analyticsUrl: string
    siteType: string
    pageId: string
    sessionId: string

    constructor(config: any) {
        const { siteProduct, analyticsUrl, siteType, pageId } = config
        this.siteProduct = siteProduct
        this.analyticsUrl = analyticsUrl
        this.siteType = siteType
        this.pageId = pageId
        this.sessionId = getSessionId()
    }

    postData = (endpoint: string, data: object) => {
        const configs = {
            site_product: this.siteProduct,
            site_type: this.siteType,
            page_id: this.pageId,
            session_id: this.sessionId
        }

        try {
            // fire and forget, it is intentional to not catch and handle any error response
            // from the fetch method
            fetch(`${this.analyticsUrl}${endpoint}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(generateAnalytics(configs, data))
        })
        } catch { /* intentionally not catching error */ }
    }
}

const generateAnalytics = (configs: any, data: any) => {
    try {
        const { site_product, site_type,  session_id } = configs
        const ptcs_guid = getPTCSGuid()
        
        return {
            event_uuid: uuidv4(),
            event_timestamp: new Date().getTime(),
            data: {
                'page_domain': document.location.hostname,
                'page_url': window.location.href,
                'ptcs_logged_in': !!ptcs_guid,
                ptcs_guid,
                session_id,
                'page_language': getLocale(),
                site_product,
                site_type,
                referrer: document.referrer,
                ...data
            }
        }
    } catch { /* intentionally not catching error */ }
}

const getPTCSGuid = () => {
    try {
        const { accessToken } = getAuthCookies()
        return JSON.parse(atob(accessToken.split('.')[1])).sub
    } catch {
        return null
    }
}

// getSessionId looks within browser session storage for a sessionId, if it doesn't exist, 
// one will be generated and placed into browser session storage before being returned
const getSessionId = () => {
    let sessionId = window.sessionStorage.getItem('sessionId')
    if(!sessionId) {
        sessionId = uuidv4()
        window.sessionStorage.setItem('sessionId', sessionId)
    }
    return sessionId
}


const getLocale = () => {
    try {
        const path = document.location.pathname
        const locale = path.split('/')[1]
        return locale
    } catch {
        return ''
    }
}

const AnalyticsCreator = (): Analytics => {
    if (!!analytics) return analytics

    const { REACT_APP_FIRST_PARTY_ANALYTICS_URL } = process.env
    return new Analytics({
        analyticsUrl: REACT_APP_FIRST_PARTY_ANALYTICS_URL as string,
        pageName: 'Rainier Code Redemption',
        siteProduct: 'rcr',
        siteType: 'Code Redemption',
    })
}

export default AnalyticsCreator
import { Suspense, lazy, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { Helmet } from 'react-helmet-async'

import NotchedBox from 'components/NotchedBox'
import GameRating from 'components/GameRating'
import { checkCanRedeem } from 'store/actions/userStatus'
import { closeNavAwayModal } from 'store/actions/navAwayModal'
import { useAppSelector } from 'hooks/store'
import { LocaleToRecaptchaLocale } from 'i18n/locales'
import { getLocale } from 'utils/url'

import styles from './CodeRedemptionView.module.scss'
import NavAwayModal from 'components/NavAwayModal'

const VerifyModule = lazy(() => import('./VerifyModule'))
const RedeemModule = lazy(() => import('./RedeemModule'))
const Maintenance = lazy(() => import('components/Maintenance'))

// CodeRedemptionView is the view where a user can submit and redeem codes
const CodeRedemptionView = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { showModal, continueLink } = useAppSelector((state) => state.navAwayModal)

    useEffect(() => {
        const { REACT_APP_GTM_ID } = process.env;
        // @ts-ignore 
        ((w,d,s,l: string,i) => {w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!=='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer',REACT_APP_GTM_ID);
    }, [])

    useEffect(() => {
        const { REACT_APP_RECAPTCHA_KEY } = process.env
        const script = document.createElement('script')
        script.src = `https://www.recaptcha.net/recaptcha/enterprise.js?render=${REACT_APP_RECAPTCHA_KEY}&hl=${LocaleToRecaptchaLocale[getLocale()] || 'en'}`            
        document.body.appendChild(script)
        return () => {
            document.body.removeChild(script)
        }
    }, [])

    useEffect(() => {
        dispatch(checkCanRedeem())
    }, [dispatch])

    const Content = () => {
        const { REACT_APP_UNDER_MAINTENANCE } = process.env
        if(!!REACT_APP_UNDER_MAINTENANCE) {
            return (
                <Suspense fallback={<></>}>
                    <Maintenance />
                </Suspense>
            )
        }
        return (
            <>
                <NotchedBox bodyClass={styles.moduleNotchedBox} notchType={'bottom'} texturize={true}>
                    <SiteDescription />
                    <CannotRedeemBanner />
                    <Suspense fallback={<></>}>
                        <VerifyModule />
                    </Suspense>
                </NotchedBox>
                <NotchedBox bodyClass={styles.moduleNotchedBox} notchType={'all'}>
                    <Suspense fallback={<></>}>
                        <RedeemModule />
                    </Suspense>
                </NotchedBox>
            </>
        )
    }
    
    return (
        <section className={styles.rootView} data-testid={'code-redemption-view'}>
            <Helmet>
                <meta name={'title'} content={t('meta.title')} />
                <meta name={'description'} content={t('meta.content')} />
                <title>{t('meta.title')}</title>
            </Helmet>
            <img className={styles.tcglLogo} src={t('img.tcglLogo')} alt={t('tcgl')} />
            {showModal && <NavAwayModal onClose={() => dispatch(closeNavAwayModal())} onContinueLink={continueLink} />}
            <NotchedBox bodyClass={styles.rootNotchedBox} title={<Trans i18nKey={'tcglTitle'} components={{ italic: <i />}} />} notchType={'bottom'}>
                <Content />
            </NotchedBox>
        </section>
    )
}

const CannotRedeemBanner = () => {
    const { t } = useTranslation()
    const { localizedError, clientError } = useAppSelector((state) => state.userStatus)
    if(localizedError || clientError) {
        return (
            <div className={styles.canRedeemError}>
                <Trans defaults={localizedError || t(clientError as string)} components={{ italic: <i />}} />
            </div>
        )
    }
    
    return <></>
}

const SiteDescription = () => {
    const { canRedeemCodes } = useAppSelector((state) => state.userStatus)

    return (
        <div className={styles.siteBanner}>
            <div>
                <Trans i18nKey={'siteDescription'} components={{ italic: <i />}} />
            </div>
            <div className={`${styles.desktopGameRating} ${canRedeemCodes && styles.showRating}`}>
                <GameRating />
            </div>
        </div>
    )
}

export default CodeRedemptionView
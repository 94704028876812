import createReducer from 'store/createReducer'
import { VerifyResponse } from 'types/verify'

export type VerifyState = {
    verifiedCodes: VerifyResponse
    verifyLoading: boolean
    deletionPending: boolean
    input: string
    clientError: string
    deletionError: string
}

const initialState: VerifyState = {
    verifiedCodes: {
        couponResults: [],
        locale: '',
        localizedError: '',
    },
    deletionPending: false,
    verifyLoading: false,
    input: '',
    clientError: '',
    deletionError: '',
}

const verify = createReducer(initialState, {
    VERIFY_CODE: <S>(state: S, payload: Partial<VerifyState>) => {
        return {
            ...state,
            ...payload,
            verifyLoading: true,
            clientError: '',
            deletionError: '',
        }
    },
    VERIFY_CODE_SUCCESS: <S>(state: S, payload: Partial<VerifyState>) => {
        return {
            ...state,
            verifiedCodes: {
                ...payload
            },
            input: '',
            clientError: '',
            verifyLoading: false
        }
    },
    VERIFY_CODE_FAILURE: <S>(state: S, payload: Partial<VerifyState>) => {
        return {
            ...state,
            verifiedCodes: {
                ...payload
            },
            input: '',
            clientError: '',
            verifyLoading: false
        }
    },
    VERIFY_CODE_CLIENT_FAILURE: <S>(state: S, payload: Partial<VerifyState>) => {
        return {
            ...state,
            ...payload,
            verifyLoading: false
        }
    },
    UPDATE_CODE_INPUT: <S>(state: S, payload: Partial<VerifyState>) => {
        return {
            ...state,
            ...payload,
            clientError: '',
            deletionError: '',
        }
    },
    CLEAR_VERIFIED_CODES: <S>(state: S, payload: Partial<VerifyState>) => {
        return {
            ...state,
            ...payload,
            clientError: '',
            deletionError: '',
        }
    },
    DELETE_CODE: <S>(state: S, payload: Partial<VerifyState>) => {
        return {
            ...state,
            ...payload,
            clientError: '',
            deletionError: '',
            deletionPending: true
        }
    },
    DELETE_CODE_SUCCESS: <S>(state: S, payload: Partial<VerifyState>) => {
        return {
            ...state,
            ...payload,
            deletionPending: false,
        }
    },
    DELETE_CODE_FAILURE: <S>(state: S, payload: Partial<VerifyState>) => {
        return {
            ...state,
            ...payload,
            deletionPending: false
        }
    },
    REMOVE_REDEEMED_CODES: <S>(state: S, payload: Partial<VerifyState>) => {
        return {
            ...state,
            ...payload,
            clientError: '',
            deletionError: '',
        }
    },
})

export default verify

export const ENGLISH_UNITED_STATES = 'en-us'
export const ENGLISH_UNITED_KINGDOM = 'en-gb'
export const ENGLISH_CANADA = 'en-ca'
export const FRENCH_FRANCE = 'fr-fr'
export const FRENCH_CANADA = 'fr-ca'
export const ITALIAN_ITALY = 'it-it'
export const GERMAN_GERMANY = 'de-de'
export const SPANISH_SPAIN = 'es-es'
export const SPANISH_LATIN_AMERICA = 'es-mx'
export const PORTUGUESE_BRAZIL = 'pt-br'
export const ENGLISH_NEW_ZEALAND = 'en-nz'
export const ENGLISH_AUSTRALIA = 'en-au'

export const LocaleToRegion: Record<string, string> = {
    [ENGLISH_UNITED_STATES]: 'United States',
    [ENGLISH_UNITED_KINGDOM]: 'UK/Ireland',
    [ENGLISH_CANADA]: 'Canada (EN)',
    [FRENCH_FRANCE]: 'France',
    [FRENCH_CANADA]: 'Canada (FR)',
    [ITALIAN_ITALY]: 'Italia',
    [GERMAN_GERMANY]: 'Deutschland',
    [SPANISH_SPAIN]: 'España',
    [SPANISH_LATIN_AMERICA]: 'América Latina',
    [PORTUGUESE_BRAZIL]: 'Brasil',
}

const LanguageTags = new Set([
    ENGLISH_UNITED_STATES,
    ENGLISH_UNITED_KINGDOM,
    ENGLISH_CANADA,
    FRENCH_FRANCE,
    FRENCH_CANADA,
    ITALIAN_ITALY,
    GERMAN_GERMANY,
    SPANISH_SPAIN,
    SPANISH_LATIN_AMERICA,
    PORTUGUESE_BRAZIL,
])

// A mapping from our locales to the locales Google uses for localizing their Recaptcha module
// https://developers.google.com/recaptcha/docs/language?hl=en
export const LocaleToRecaptchaLocale: Record<string, string> = {
    [ENGLISH_UNITED_STATES]: 'en',
    [ENGLISH_UNITED_KINGDOM]: 'en-GB',
    [ENGLISH_CANADA]: 'en',
    [FRENCH_FRANCE]: 'fr',
    [FRENCH_CANADA]: 'fr-CA',
    [ITALIAN_ITALY]: 'es',
    [GERMAN_GERMANY]: 'de',
    [SPANISH_SPAIN]: 'es',
    [SPANISH_LATIN_AMERICA]: 'es-419',
    [PORTUGUESE_BRAZIL]: 'pt-BR',
}

export default LanguageTags
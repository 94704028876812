import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Modal from 'components/Modal'
import styles from './NavAwayModal.module.scss'

type NavAwayModalType = {
    onClose: () => void
    onContinueLink: string
}

const NavAwayModal: FC<NavAwayModalType> = ({ onClose, onContinueLink }) => {
    const { t } = useTranslation()
    return (
        <Modal onClose={onClose} contentClassName={styles.overrideModalContent}>
            <div data-testid={'nav-away-modal'}>
                <div className={styles.header}>
                    {t('navAwayModal.header')}
                </div>
                <div className={styles.body}>
                    {t('navAwayModal.body')}
                </div>
                <a className={styles.continueLink} href={onContinueLink} target={'_blank'} rel={'noreferrer'}>
                    {t('continue')}
                </a>
            </div>
        </Modal>
    )
}

export default NavAwayModal
import { logout } from 'store/actions/auth'

let timeout: NodeJS.Timeout | null = null
export const setIdleLogoutTime = () => {
    // reset existing timeout
    clearTimeout(timeout as NodeJS.Timeout)
    timeout = setTimeout(() => {
        clearTimeout(timeout as NodeJS.Timeout)
        logout(window.location.href)
        // 1,800,000 ms is equivalent to 30 minutes
    }, 1800000)
}
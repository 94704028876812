import Locales from 'i18n/locales'

import { ENGLISH_UNITED_STATES, ENGLISH_UNITED_KINGDOM, ENGLISH_AUSTRALIA, ENGLISH_NEW_ZEALAND } from 'i18n/locales'

export const getLocale = () => {
    const path = window.location.pathname
    const locale = path.split('/')[1]
    // en-nz and en-au are not locales that have their own separate translations so they exist only to default to en-gb
    if(locale === ENGLISH_NEW_ZEALAND || locale === ENGLISH_AUSTRALIA) {
        return ENGLISH_UNITED_KINGDOM
    }
    return Locales.has(locale) ? locale : ENGLISH_UNITED_STATES
}
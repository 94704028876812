import createReducer from 'store/createReducer'

export type UserStatusState = {
    canRedeemCodes: boolean
    forwardUrl: string | null
    localizedError: string | null
    userStatusLoading: boolean
    clientError: string | null
}

const initialState: UserStatusState = {
    canRedeemCodes: false,
    forwardUrl: null,
    localizedError: null,
    userStatusLoading: false,
    clientError: null,
}

const verify = createReducer(initialState, {
    GET_CAN_REDEEM: <S>(state: S, payload: Partial<UserStatusState>) => {
        return {
            ...state,
            ...payload,
            userStatusLoading: true
        }
    },
    GET_CAN_REDEEM_SUCCESS: <S>(state: S, payload: Partial<UserStatusState>) => {
        return {
            ...state,
            ...payload,
            userStatusLoading: false
        }
    },
    GET_CAN_REDEEM_FAILURE: <S>(state: S, payload: Partial<UserStatusState>) => {
        return {
            ...state,
            ...payload,
            userStatusLoading: false
        }
    }
})

export default verify
import createReducer from 'store/createReducer'

export type AuthState = {
    authLoading: boolean
    screenName: string
}

const initialState: AuthState = {
    authLoading: true,
    screenName: '',
}

const verify = createReducer(initialState, {
    GET_AUTH_TOKENS: <S>(state: S, payload: Partial<AuthState>) => {
        return {
            ...state,
            ...payload,
            authLoading: true,
        }
    },
    GET_AUTH_TOKENS_SUCCESS: <S>(state: S, payload: Partial<AuthState>) => {
        return {
            ...state,
            ...payload,
            authLoading: false,
        }
    },
    GET_REFRESHED_TOKENS: <S>(state: S, payload: Partial<AuthState>) => {
        return {
            ...state,
            ...payload,
            authLoading: true,
        }
    },
    GET_REFRESHED_TOKENS_SUCCESS: <S>(state: S, payload: Partial<AuthState>) => {
        return {
            ...state,
            ...payload,
            authLoading: false,
        }
    },
})

export default verify
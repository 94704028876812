import { AppDispatch } from 'store'

export const setNavAwayModal = (continueLink: string) => {
    return (dispatch: AppDispatch) => {
        dispatch({type: 'SET_NAV_AWAY_MODAL', payload: {continueLink}})
    }
}

export const closeNavAwayModal = () => {
    return (dispatch: AppDispatch) => {
        dispatch({type: 'CLOSE_NAV_AWAY_MODAL', payload: {}})
    }
}

import { useTranslation  } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { setNavAwayModal } from 'store/actions/navAwayModal'
import { useLocale } from 'hooks/url'
import { GERMAN_GERMANY } from 'i18n/locales'

import styles from './GameRating.module.scss'

const GameRating = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const locale = useLocale()

    const gameRatingLink = t('gameRatingLink')
    const ausGameRatingLink = t('ausGameRatingLink')
    const onClickCallback = () => dispatch(setNavAwayModal(gameRatingLink))
    const ausOnClickCallback = () => dispatch(setNavAwayModal(ausGameRatingLink))
    const ausGameRatingSrc = t('img.ausGameRating')
    
    const GameRatingDescription = () => {
        const ratingDescription = t('gameRatingDescription')
        return !!ratingDescription ? <div className={styles.ratingDescription}>{ratingDescription}</div> : <></>
    }

    // German Rating image contains smaller text so this is a one off case where the image needs to be larger
    const ratingClass = locale === GERMAN_GERMANY ? styles.germanRatingImg : styles.ratingImg
    let Rating = () => <img data-testid={'game-rating-img'} className={ratingClass} src={t('img.gameRating')} alt={'rating'} />

    return (
        <>
            <div className={styles.nonAusWrapper} onClick={onClickCallback}>
                <Rating />
                <GameRatingDescription />
            </div>
            {!!ausGameRatingSrc && <img data-testid={'aus-game-rating-img'} onClick={ausOnClickCallback} className={styles.ratingImg} src={ausGameRatingSrc} alt={'rating'} />}
        </>
    )
}

export default GameRating
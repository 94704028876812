import createReducer from 'store/createReducer'

export type NavAwayModalState = {
    showModal: boolean
    continueLink: string
}

const initialState: NavAwayModalState = {
    showModal: false,
    continueLink: '',
}

const navAwayModal = createReducer(initialState, {
    SET_NAV_AWAY_MODAL: <S>(state: S, payload: Partial<NavAwayModalState>) => {
        return {
            ...state,
            ...payload,
            showModal: true,
        }
    },
    CLOSE_NAV_AWAY_MODAL: <S>(state: S, payload: Partial<NavAwayModalState>) => {
        return {
            ...state,
            ...payload,
            showModal: false,
            continueLink: '',
        }
    },
})

export default navAwayModal
import createReducer from 'store/createReducer'
import { RedeemResponse } from 'types/redeem'

export type RedeemState = {
    clientError: string,
    redeemLoading: boolean,
} & RedeemResponse

const initialState: RedeemState = {
    redeemCouponResults: [],
    locale: '',
    localizedError: '',
    error: '',
    clientError: '',
    redeemLoading: false,
}

const redeem = createReducer(initialState, {
    REDEEM_CODES: <S>(state: S, payload: Partial<RedeemState>) => {
        return {
            ...state,
            ...initialState,
            redeemLoading: true,
            redeemCouponResults: [],
        }
    },
    REDEEM_CODES_SUCCESS: <S>(state: S, payload: Partial<RedeemState>) => {
        return {
            ...state,
            ...payload,
            redeemLoading: false,
        }
    },
    REDEEM_CODES_FAILURE: <S>(state: S, payload: Partial<RedeemState>) => {
        return {
            ...state,
            ...payload,
            redeemLoading: false,
        }
    },
    REDEEM_CODES_CLIENT_FAILURE: <S>(state: S, payload: Partial<RedeemState>) => {
        return {
            ...state,
            ...payload,
            redeemLoading: false,
        }
    },
    REDEEM_CODES_CLEAR_RESULTS: <S>(state: S, payload: Partial<RedeemState>) => {
        return {
            ...state,
            redeemCouponResults: [],
        }
    }
})

export default redeem

type CookieConfig = {
  name: string
  value: string
  expireTimeInSeconds: number
  sameSite?: 'None' | 'Lax' | 'Strict'
  secure?: boolean
}

export const setCookie = (cookieConfig: CookieConfig) => {
  const { expireTimeInSeconds, name, value, secure, sameSite } = cookieConfig
  const currentDateTime = new Date()
  const cookieExpirationTime = new Date(currentDateTime.getTime() + expireTimeInSeconds * 1000)

  // SameSite=Strict configuration disallows the cookie from being sent to any cross-origin site
  // secure only allows the cookie to be sent across https
  let cookie = `${name}=${value};expires=${cookieExpirationTime.toUTCString()};SameSite=${sameSite || 'Lax'};path=/;`
  if(!!secure) {
    cookie = `${cookie};secure`
  }
  document.cookie = cookie
}

export const getCookie = (cookieName: string): string => {
    const name = `${cookieName}=`
    const decodedCookie = decodeURIComponent(document.cookie)
    const cookieAttributes = decodedCookie.split(';')

    for(let i = 0; i < cookieAttributes.length; i++) {
        let attribute = cookieAttributes[i]
        while (attribute.charAt(0) === ' ') {
            attribute = attribute.substring(1)
        }
        if (attribute.indexOf(name) === 0) {
          return attribute.substring(name.length, attribute.length);
        }
    }
    return ''
}
import { getCookie } from 'utils/cookie'
import cryptoBrowserify from 'crypto-browserify'

type AuthCookies = {
    accessToken: string
    refreshToken: string
}
export const getAuthCookies = (): AuthCookies => {
    return {
        accessToken: getCookie('a_token'),
        refreshToken: getCookie('r_token')
    }
}

export const base64URLEncode = (bytes: Uint8Array): string => {
    const binStr: Array<string> = []
    bytes.forEach((code: number) => {
        binStr.push(String.fromCharCode(code))
    })
    return btoa(binStr.join(''))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '')
}

export const generateCodeVerifier = (): string => {
   return base64URLEncode(cryptoBrowserify.randomBytes(32))
}

export const generateCodeChallenge = (codeVerifier: string): string => {
    return base64URLEncode(cryptoBrowserify.createHash('sha256').update(codeVerifier).digest())
}

export const generateOAuthState = (): string => {
    return base64URLEncode(cryptoBrowserify.randomBytes(16))
}

export const redirectToLogin = () => {
    const { REACT_APP_AUTH_URL, REACT_APP_REDIRECT_URL } = process.env

    const codeVerifier = generateCodeVerifier()
    const codeChallenge = generateCodeChallenge(codeVerifier)
    const state = generateOAuthState()

    storeAuthParameters({codeVerifier, state})

    const scope = encodeURIComponent('openid offline screen_name')
    const redirectUri = encodeURIComponent(REACT_APP_REDIRECT_URL as string)
    const clientId = 'rainier-code-redemption'
    const responseType = 'code'
    const codeChallengeMethod = 'S256'

    const loginLink = `${REACT_APP_AUTH_URL}/auth?client_id=${clientId}&response_type=${responseType}&` +
    `state=${state}&scope=${scope}&code_challenge=${codeChallenge}&code_challenge_method=${codeChallengeMethod}` +
    `&redirect_uri=${redirectUri}&audience=`

    window.location.assign(loginLink)
}

export type AuthBrowserParameters = {
    codeVerifier: string
    state: string
}

export const storeAuthParameters = (params: AuthBrowserParameters) => {
    window.localStorage.setItem('codeVerifier', params.codeVerifier)
    window.localStorage.setItem('state', params.state)
}

export const getAuthParameters = (): AuthBrowserParameters | null => {
    const codeVerifier = window.localStorage.getItem('codeVerifier')
    const state = window.localStorage.getItem('state')

    if(!codeVerifier || !state) {
        return null
    }

    return {
        codeVerifier,
        state,
    }
}

const decodeJWTClaims = () => {
    const { accessToken } = getAuthCookies()
    return JSON.parse(atob(accessToken.split('.')[1]))
}

export const getScreenName = () => {
    try {
        const { ext: { screen_name } } = decodeJWTClaims()
        return screen_name
    } catch(e) {
        return ''
    }
}


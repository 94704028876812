import axios from 'axios'

import { getLocale } from 'utils/url'
import { getCookie } from 'utils/cookie'
import { AppDispatch } from 'store'
import Analytics from 'utils/analytics'

export const checkCanRedeem = () => {
    const { REACT_APP_GSB_API_URL } = process.env
    const headers = {
        'Authorization': `basic ${getCookie('a_token')}`,
        'Content-Type': 'application/json'
    }
    
    const locale = getLocale()
    return async (dispatch: AppDispatch) => {
        let canRedeemCodes = false
        try {
            dispatch({type: 'GET_CAN_REDEEM', payload: {}})
            const requestBody = {
                locale
            }
            const { data } = await axios.post(`${REACT_APP_GSB_API_URL}/can_redeem`, requestBody, { headers })
            canRedeemCodes = !!data.canRedeemCodes
            dispatch({type: 'GET_CAN_REDEEM_SUCCESS', payload: data})
        } catch (err) {
            if (axios.isAxiosError(err)) {
                canRedeemCodes = !!err.response?.data.canRedeemCodes
                const errResponse = err.response?.data || { clientError: 'error.unknownError' }
                dispatch({type: 'GET_CAN_REDEEM_FAILURE', payload: errResponse})
            }
        } finally {
            // page is considered to have finished loading once the api to see if a user can redeem is completed
            const analytics = Analytics()
            analytics.postData('/page-load/v02/record', {can_redeem_codes: canRedeemCodes})
        }
    }
}
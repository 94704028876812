import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { getLocale } from 'utils/url'

import enUS from './locales/en-us.json'
import enGB from './locales/en-gb.json'
import enCA from './locales/en-ca.json'
import frFR from './locales/fr-fr.json'
import frCA from './locales/fr-ca.json'
import itIT from './locales/it-it.json'
import deDE from './locales/de-de.json'
import esES from './locales/es-es.json'
import esMX from './locales/es-mx.json'
import ptBR from './locales/pt-br.json'

import {
  ENGLISH_UNITED_STATES,
  ENGLISH_UNITED_KINGDOM,
  ENGLISH_CANADA,
  FRENCH_FRANCE,
  FRENCH_CANADA,
  ITALIAN_ITALY,
  GERMAN_GERMANY,
  SPANISH_SPAIN,
  SPANISH_LATIN_AMERICA,
  PORTUGUESE_BRAZIL,
} from './locales'

const resources = {
  [ENGLISH_UNITED_STATES]: {
    translation: enUS
  },
  [ENGLISH_UNITED_KINGDOM]: {
    translation: enGB
  },
  [ENGLISH_CANADA]: {
    translation: enCA
  },
  [FRENCH_FRANCE]: {
    translation: frFR
  },
  [FRENCH_CANADA]: {
    translation: frCA
  },
  [ITALIAN_ITALY]: {
    translation: itIT
  },
  [GERMAN_GERMANY]: {
    translation: deDE
  },
  [SPANISH_SPAIN]: {
    translation: esES
  },
  [SPANISH_LATIN_AMERICA]: {
    translation: esMX
  },
  [PORTUGUESE_BRAZIL]: {
    translation: ptBR
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getLocale(),
    fallbackLng: ENGLISH_UNITED_STATES,
    keySeparator: false,
    interpolation: {
      escapeValue: false
    },
    lowerCaseLng: true,
  })

  export default i18n
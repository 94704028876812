import { applyMiddleware, combineReducers, createStore, AnyAction } from 'redux'
import thunk, { ThunkDispatch } from 'redux-thunk'
import logger from 'redux-logger'

import auth, { AuthState } from './reducers/auth'
import userStatus, { UserStatusState } from './reducers/userStatus'
import verify, { VerifyState } from './reducers/verify'
import redeem, { RedeemState } from './reducers/redeem'
import navAwayModal, { NavAwayModalState } from './reducers/navAwayModal'

type ReduxMiddleware = typeof thunk | typeof logger

const middleware: Array<ReduxMiddleware> = [thunk]
if (process.env.NODE_ENV === 'development') {
    middleware.push(logger)
}
const applicationReducer = combineReducers({auth, userStatus, verify, redeem, navAwayModal})
const store = createStore(applicationReducer, applyMiddleware(...middleware))

export type RootState = {
    verify: VerifyState
    auth: AuthState
    userStatus: UserStatusState
    redeem: RedeemState
    navAwayModal: NavAwayModalState,
}

export type AppDispatch = typeof store.dispatch & ThunkDispatch<any, undefined, AnyAction>

// generateRecaptchaToken takes in a callback function where the argument
// is the generated reCaptcha token
export const generateRecaptchaToken = ((callback: (token: string) => void) => {
    const { REACT_APP_RECAPTCHA_KEY } = process.env
    window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise.execute(REACT_APP_RECAPTCHA_KEY as string, {action: 'submit'}).then((token: string) => {
            callback(token)
        })
    })
})

export default store
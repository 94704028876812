import { FC, useEffect } from 'react'

import styles from './Modal.module.scss'

const CloseIcon = () => {
    return (
        <svg id="icon-close" viewBox="0 0 17 18" height="18">
            <g fill="currentColor" fillRule="evenodd">
                <path d="M.207 15.202L14.349.228l2.121 2.246L2.328 17.448z" />
                <path d="M2.328.228L16.47 15.202l-2.121 2.246L.207 2.474z" />
            </g>
        </svg>
    )
}

type ModalType = {
    onClose: () => void
    contentClassName?: string
}

const Modal: FC<ModalType> = ({ children, onClose, contentClassName }) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = 'visible'
        }
    }, [])

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                onClose();
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [onClose]);
    
    return (
        <div className={styles.modalRoot} onClick={onClose}>
            <button data-testid={'modal-close-btn'} className={styles.closeButton} onClick={onClose}>
                <CloseIcon />
            </button>
            <div className={`${styles.modalContent} ${!!contentClassName ? contentClassName : ''}`} onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    )
}

export default Modal
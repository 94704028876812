import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, RouteProps } from 'react-router-dom'

import { useQuery } from 'hooks/url'
import { useAppSelector } from 'hooks/store'
import { getAuthTokens, refreshTokens } from 'store/actions/auth'
import { redirectToLogin, getAuthCookies } from 'utils/auth'

import styles from './ProtectedRoute.module.scss'
import pokeball from 'assets/images/pokeball.png'

const Loading = () => {
  return (
    <section className={styles.loading}>
      <img className={styles.loadingPokeball} src={pokeball} alt={'loading'} />
    </section>
  )
}

// ProtectedRoute is a component that wraps another component. It will redirect the user
// to the login page if they are not authenticated
const ProtectedRoute: FC<RouteProps> = ({ children, ...routeProps }) => {
    const { authLoading } = useAppSelector((state => state.auth))
    const dispatch = useDispatch()
    const query = useQuery()
    const authCode = query.get('code')
    const { accessToken, refreshToken } = getAuthCookies()

    useEffect(() => {
      dispatch(getAuthTokens(authCode))
    }, [authCode, dispatch])

    useEffect(() => {
      // access token has expired but refresh token still has not
      if(!accessToken && !!refreshToken) {
        dispatch(refreshTokens())
      }
      // redirect to login if user is not authenticated
      else if(!accessToken && !refreshToken && !authCode) {
        redirectToLogin()
      }
    }, [accessToken, refreshToken, authCode, dispatch])
  
    return (
      <Route {...routeProps} >
        {(!authLoading || !!accessToken) ? children : <Loading />}
      </Route>
    )
  }

  export default ProtectedRoute
  